import { OptionModel } from '@komo-tech/core/models/OptionModel';
import { BillingSubscriptionModifierTypes } from '@/common/models/billing/subscriptions/modifiers/shared/BillingSubscriptionModifierTypes';

import { BillingFeatureTypes } from './BillingFeatureTypes';
import { BillingFeatureTypeVariableTypes } from './BillingFeatureTypeVariableTypes';

export class BillingFeatureTypeDefinition {
  feature: BillingFeatureTypes;
  variableType: BillingFeatureTypeVariableTypes;
  name: string;
  description: string;
  supportedModifierTypes: BillingSubscriptionModifierTypes[];

  constructor(props?: Partial<BillingFeatureTypeDefinition>) {
    props = props || {};
    Object.assign(this, props);
  }

  toOptionModel(): OptionModel {
    return {
      label: this.name,
      value: this.feature,
      description: this.description
    };
  }

  get isNumber() {
    return this.variableType === BillingFeatureTypeVariableTypes.Number;
  }
}
