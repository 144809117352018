import { addDays, differenceInSeconds } from 'date-fns';
import sortBy from 'lodash/sortBy';
import sum from 'lodash/sum';

import { Guid } from '@komo-tech/core/models/Guid';
import { MinMax } from '@komo-tech/core/models/MinMax';
import { tryReplaceItemInArray } from '@komo-tech/core/utils/array';
import { asBoolean } from '@komo-tech/core/utils/boolean';
import { utcFormatDateTimeRangeShort } from '@komo-tech/core/utils/date';
import {
  getCreditsEndsAt,
  getCreditsStartsAt,
  wasCreditsTerminated
} from '@/common/utils/PlanFunctions';

import { CompanyPlanCreditGroupAddOn } from './CompanyPlanCreditGroupAddOn';
import { CompanyPriceCredit } from './CompanyPriceCredit';

export class CompanyPriceCreditGroup {
  id: Guid;
  credits: CompanyPriceCredit[];
  addOns: CompanyPlanCreditGroupAddOn[];
  properties?: CompanyPlanCreditGroupProperties;
  isNew?: boolean;

  get startsAt() {
    return getCreditsStartsAt(this.credits);
  }

  get endsAt() {
    return getCreditsEndsAt(this.credits);
  }

  get wasTerminated() {
    return wasCreditsTerminated(this.credits);
  }

  get dateRange(): MinMax<Date> {
    return { min: this.startsAt, max: this.endsAt };
  }

  get isActive() {
    return this.credits.some((x) => x.isActive());
  }

  get daysDuration() {
    return sum(this.credits.map((x) => x.daysDuration));
  }

  get dateRangeDisplay() {
    if (!this.startsAt) return '-';
    return utcFormatDateTimeRangeShort(this.startsAt, this.endsAt);
  }

  get secondsRemaining() {
    return differenceInSeconds(this.endsAt, new Date());
  }

  get secondsRemainingOfCurrentCredit() {
    return this.secondsRemaining % CompanyPriceCredit.DefaultSecondsDuration;
  }

  constructor(props: Partial<CompanyPriceCreditGroup> = {}) {
    props = props || {};
    Object.assign(this, props);
    this.addOns = (props.addOns || []).map(
      (x) => new CompanyPlanCreditGroupAddOn(x)
    );
    this.id = Guid.valueOrNew(props.id);
    this.isNew = asBoolean(props.isNew);
    this.credits = (props.credits || []).map((x) => new CompanyPriceCredit(x));
    this.properties = new CompanyPlanCreditGroupProperties(props.properties);
  }

  addCredit(credit: CompanyPriceCredit) {
    this.credits.push(credit);
    this.sanitiseCreditDates();
  }

  addCredits(credits: CompanyPriceCredit[]) {
    credits.forEach((c) => this.addCredit(c));
  }

  updateCredit(credit: CompanyPriceCredit) {
    tryReplaceItemInArray(this.credits, (x) => x.id.equals(credit.id), credit);
    this.sanitiseCreditDates();
  }

  setStartDate(value: Date) {
    if (this.credits.length) {
      sortBy(this.credits, (x) => x.expiresAt);
      this.credits.forEach((c, i) => {
        c.startsAt = i === 0 ? value : undefined;
      });
      this.sanitiseCreditDates();
    }
  }

  sanitiseCreditDates() {
    if (!this.credits.length) return;
    this.credits = sortBy(this.credits, (x) => x.startsAt);
    if (this.startsAt) {
      let nextStarted = this.startsAt;
      this.credits.forEach((x) => {
        x.startsAt = nextStarted;
        nextStarted = addDays(nextStarted, x.daysDuration);
      });
    }
  }

  hasUnPublishLock() {
    return this.properties.UnPublishCreditLock;
  }

  getUnUsedCredits() {
    return this.credits.filter((x) => x.hasNotStarted());
  }
}

export class CompanyPlanCreditGroupProperties {
  Notes: string;
  UnPublishCreditLock: boolean;

  constructor(props: Partial<CompanyPlanCreditGroupProperties> = {}) {
    Object.assign(this, props);
    if (!this.Notes) this.Notes = '';
    this.UnPublishCreditLock = asBoolean(props.UnPublishCreditLock);
  }
}
