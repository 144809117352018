import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';

import { Guid } from '@komo-tech/core/models/Guid';
import { MinMax } from '@komo-tech/core/models/MinMax';
import { OptionModel } from '@komo-tech/core/models/OptionModel';
import {
  utcDateOrNow,
  utcFormatDateTimeRange
} from '@komo-tech/core/utils/date';

interface GetCampaignOptions {
  siteId?: Guid;
  excludePlanIds?: Guid[];
  isActive?: boolean;
}

export class CompanyPricePlanOptions {
  creditGroupAddOns: OptionModel<string>[];
  plans: OptionModel<string>[];
  sites: CompanyPricePlanOptionsSite[];

  constructor(props?: Partial<CompanyPricePlanOptions>) {
    props = props || {};
    Object.assign(this, props);

    if (!this.creditGroupAddOns?.length) this.creditGroupAddOns = [];
    if (!this.plans?.length) this.plans = [];
    this.sites = (props.sites || []).map(
      (x) => new CompanyPricePlanOptionsSite(x)
    );
  }

  siteHasActiveCampaigns(siteId: Guid, excludePlanIds?: Guid[]) {
    return this.getCampaigns({ siteId, excludePlanIds, isActive: true }).length
      ? true
      : false;
  }

  getSiteOptionsForNewCampaign(excludeSiteIds: Guid[], lockedSiteId?: Guid) {
    return this.sites
      .filter((x) => {
        if (lockedSiteId && x.id.equals(lockedSiteId)) return true;
        return (
          !x.hasActiveCampaign && !excludeSiteIds.some((c) => c.equals(x.id))
        );
      })
      .map<OptionModel<string>>((x) => ({
        label: x.name,
        value: x.id.toString()
      }));
  }

  getDateRangesForSite(siteId: Guid): MinMax<Date>[] {
    const site = this.sites.find((x) => x.id.equals(siteId));
    return !site ? [] : site.getCampaignDateRanges();
  }

  getCampaigns(options?: GetCampaignOptions) {
    const campaigns: CompanyPricePlanOptionsSiteCampaign[] = [];
    this.sites.forEach((s) => {
      if (options?.siteId && !s.id.equals(options.siteId)) {
        return;
      }

      s.campaigns.forEach((c) => {
        if (
          options?.excludePlanIds?.length &&
          options?.excludePlanIds.some((p) => p.equals(c.pricePlanId))
        ) {
          return;
        }

        if (options?.isActive && !c.isActive()) {
          return;
        }
        campaigns.push(c);
      });
    });
    return campaigns;
  }
}

export class CompanyPricePlanOptionsSite {
  id: Guid;
  name: string;
  domainName: string;
  campaigns: CompanyPricePlanOptionsSiteCampaign[];

  get hasCampaigns() {
    return this.campaigns?.length ? true : false;
  }

  get hasActiveCampaign() {
    return this.getActiveCampaign() ? true : false;
  }

  constructor(props?: Partial<CompanyPricePlanOptionsSite>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrNew(props.id);
    this.campaigns = (props.campaigns || []).map(
      (x) => new CompanyPricePlanOptionsSiteCampaign(x)
    );
  }

  getActiveCampaign() {
    return (this.campaigns || []).find((x) => x.isActive());
  }

  getCampaignDateRanges() {
    const ranges: MinMax<Date>[] = [];

    (this.campaigns || []).forEach((c) => {
      ranges.push({ min: c.started, max: c.ends });
    });
    return ranges;
  }
}

export class CompanyPricePlanOptionsSiteCampaign {
  id: Guid;
  pricePlanId: Guid;
  pricePlanName: string;
  started: Date;
  ends: Date;

  get dateRange(): MinMax<Date> {
    return { min: this.started, max: this.ends };
  }

  get dateRangeDisplay() {
    return utcFormatDateTimeRange(this.started, this.ends, {
      startVariant: 'Date',
      endVariant: 'Date'
    });
  }

  constructor(props?: Partial<CompanyPricePlanOptionsSiteCampaign>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrNew(props.id);
    this.pricePlanId = Guid.valueOrNew(props.pricePlanId);
    this.started = utcDateOrNow(props.started);
    this.ends = utcDateOrNow(props.ends);
  }

  isActive() {
    const now = new Date();
    return isAfter(now, this.started) && isBefore(now, this.ends);
  }

  isCompleted() {
    const now = new Date();
    return isAfter(this.ends, now);
  }
}
