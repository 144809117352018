import { differenceInDays } from 'date-fns';

import { Guid } from '@komo-tech/core/models/Guid';
import { asBoolean } from '@komo-tech/core/utils/boolean';
import {
  utcDateOrNow,
  utcDateOrUndefined,
  utcFormatDate
} from '@komo-tech/core/utils/date';

import { PriceAddOnStatuses } from '../pricing/PriceAddOnStatuses';

export class CompanyPlanCreditGroupAddOn {
  id: Guid;
  started: Date;
  addOnId: Guid;
  addOnName: string;
  addOnStatus: PriceAddOnStatuses;
  addOnUpdatedAt: Date;
  isNew?: boolean;

  get addOnDisplayName() {
    if (this.addOnStatus === PriceAddOnStatuses.Grandfathered) {
      return `Legacy ${this.addOnName} [${utcFormatDate(this.addOnUpdatedAt)}]`;
    }
    return this.addOnName;
  }

  constructor(props?: Partial<CompanyPlanCreditGroupAddOn>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrNew(props.id);
    this.started = utcDateOrUndefined(props.started);
    this.addOnId = Guid.valueOrNew(props.addOnId);
    this.isNew = asBoolean(props.isNew);
    this.addOnUpdatedAt = utcDateOrNow(props.addOnUpdatedAt);
  }

  getDateRange(campaignEndDate: Date) {
    return {
      min: this.started,
      max: campaignEndDate,
      days: differenceInDays(campaignEndDate, this.started)
    };
  }
}
