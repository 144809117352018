import isArray from 'lodash/isArray';

import { Guid } from '@komo-tech/core/models/Guid';
import { mapArray } from '@komo-tech/core/utils/array';

import { PriceFeatures } from './PriceFeatures';
import { PriceFeatureVariableTypes } from './PriceFeatureTypes';

export class PriceFeatureModel {
  id: Guid;
  feature: PriceFeatures;
  type: PriceFeatureVariableTypes;
  numberValue?: number;
  constructor(props?: Partial<PriceFeatureModel>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrNew(props.id);
  }

  static listFromJson(json: string) {
    const fallback: PriceFeatureModel[] = [];
    try {
      const rawItems = JSON.parse(json || '[]');
      if (!isArray(rawItems)) {
        return fallback;
      }

      return mapArray(rawItems, (x) => new PriceFeatureModel(x));
    } catch (error) {
      console.error(error);
      return fallback;
    }
  }
}
